.measurementItem .toggle-anno-btn {
  margin-left: auto;
  margin-right: 1rem;
  padding: 2px;
  line-height: 0.7;
}
.measurementItem .rowActions {
  margin-left: -1px;
  background-color: var(--ui-gray-darker);
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  visibility: hidden;
  padding-left: 14px;
}
.measurementItem .rowActions .btnAction {
  background-color: transparent;
  border: none;
  color: var(--default-color);
  cursor: pointer;
  line-height: 35px;
  height: 35px;
  transition: all 0.3s ease;
}
.measurementItem .rowActions .btnAction:hover,
.measurementItem .rowActions .btnAction:active {
  color: var(--text-primary-color);
}
.measurementItem .rowActions .btnAction i {
  margin-right: 4px;
}
.measurementItem.selected .rowActions {
  height: auto;
  visibility: visible;
}
.measurementItem .comment {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  visibility: hidden;
}
.measurementItem.selected .comment {
  height: auto;
  visibility: visible;
}
.measurementItem .measurementLocation {
  margin-top: 9px;
  overflow: hidden;
  max-width: calc(100% - 50px);
  text-overflow: ellipsis;
  overflow-wrap: normal;
  white-space: nowrap;
}
.measurementItem .measurementDisplayText {
  display: inline-block;
  margin-top: 9px;
  padding-left: 9px;
  border-left: 1px solid var(--text-secondary-color);
  color: var(--text-primary-color);
}
.measurementItem .itemIndex {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}
.measurementItem.hasWarnings .itemIndex {
  opacity: 1;
  background-color: #e29e4a;
  color: #fff;
}
.measurementItem.hasWarnings .warning-icon {
  display: block;
  margin: 7px auto 0;
}
.measurementItem.hasWarnings .warning-icon svg {
  width: 22px;
  height: 20px;
  pointer-events: inherit;
}
