.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding-bottom: 20px;
  padding-top: 10px;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.study-browser .scrollable-study-thumbnails .study-thumbs-wrapper {
  background-color: var(--ui-border-color);
  border: 2px solid var(--text-secondary-color);
  border-radius: 7px;
  margin: 5px 5px 5px 0px;
  font-size: 0.85rem;
  padding: 0px 4px 0px 4px;
}
.study-browser .scrollable-study-thumbnails .study-label {
  margin-bottom: 10px;
}
.study-browser .scrollable-study-thumbnails .toggle-thumbs-btn {
  margin-left: auto;
  font-size: 16px;
  padding: 2px;
  line-height: 0.7;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 0rem;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.foo {
  color: #00f;
}
